import * as React from 'react';
import { isBandScale } from '../internals/isBandScale';
export function getTicksNumber(params) {
  const {
    maxTicks = 999,
    minTicks = 2,
    tickSpacing = 50,
    range
  } = params;
  const estimatedTickNumber = Math.floor(Math.abs(range[1] - range[0]) / tickSpacing);
  return Math.min(maxTicks, Math.max(minTicks, estimatedTickNumber));
}
function useTicks(options) {
  const {
    scale,
    ticksNumber,
    valueFormatter
  } = options;
  return React.useMemo(() => {
    // band scale
    if (isBandScale(scale)) {
      const domain = scale.domain();
      if (scale.bandwidth() > 0) {
        // scale type = 'band'
        return [...domain.map((value, index) => {
          var _valueFormatter;
          return {
            formattedValue: (_valueFormatter = valueFormatter == null ? void 0 : valueFormatter(value)) != null ? _valueFormatter : value,
            offset: index === 0 ? scale.range()[0] : scale(value) - (scale.step() - scale.bandwidth()) / 2,
            labelOffset: scale.step() / 2
          };
        }), {
          formattedValue: undefined,
          offset: scale.range()[1],
          labelOffset: 0
        }];
      }

      // scale type = 'point'
      return domain.map(value => {
        var _valueFormatter2;
        return {
          formattedValue: (_valueFormatter2 = valueFormatter == null ? void 0 : valueFormatter(value)) != null ? _valueFormatter2 : value,
          offset: scale(value),
          labelOffset: 0
        };
      });
    }
    return scale.ticks(ticksNumber).map(value => {
      var _valueFormatter3;
      return {
        formattedValue: (_valueFormatter3 = valueFormatter == null ? void 0 : valueFormatter(value)) != null ? _valueFormatter3 : scale.tickFormat(ticksNumber)(value),
        offset: scale(value),
        labelOffset: 0
      };
    });
  }, [ticksNumber, scale, valueFormatter]);
}
export default useTicks;