import _extends from "@babel/runtime/helpers/esm/extends";
import { stack as d3Stack } from 'd3-shape';
import { getStackingGroups } from '../internals/stackSeries';
import defaultizeValueFormatter from '../internals/defaultizeValueFormatter';
const formatter = params => {
  const {
    seriesOrder,
    series
  } = params;
  const stackingGroups = getStackingGroups(params);

  // Create a data set with format adapted to d3
  const d3Dataset = [];
  seriesOrder.forEach(id => {
    series[id].data.forEach((value, index) => {
      if (d3Dataset.length <= index) {
        d3Dataset.push({
          [id]: value
        });
      } else {
        d3Dataset[index][id] = value;
      }
    });
  });
  const completedSeries = {};
  stackingGroups.forEach(stackingGroup => {
    const {
      ids,
      stackingOffset,
      stackingOrder
    } = stackingGroup;
    // Get stacked values, and derive the domain
    const stackedSeries = d3Stack().keys(ids).order(stackingOrder).offset(stackingOffset)(d3Dataset);
    ids.forEach((id, index) => {
      completedSeries[id] = _extends({}, series[id], {
        stackedData: stackedSeries[index].map(([a, b]) => [a, b])
      });
    });
  });
  return {
    seriesOrder,
    stackingGroups,
    series: defaultizeValueFormatter(completedSeries, v => v.toLocaleString())
  };
};
export default formatter;