export const getExtremumX = params => {
  var _axis$data, _axis$data2;
  const {
    axis
  } = params;
  const minX = Math.min(...((_axis$data = axis.data) != null ? _axis$data : []));
  const maxX = Math.max(...((_axis$data2 = axis.data) != null ? _axis$data2 : []));
  return [minX, maxX];
};
export const getExtremumY = params => {
  const {
    series,
    axis,
    isDefaultAxis
  } = params;
  return Object.keys(series).filter(seriesId => series[seriesId].yAxisKey === axis.id || isDefaultAxis && series[seriesId].yAxisKey === undefined).reduce((acc, seriesId) => {
    const isArea = series[seriesId].area !== undefined;
    const getValues = isArea ? d => d : d => [d[1], d[1]]; // Id area should go from bottom to top, without area should only consider the top

    const [seriesMin, seriesMax] = series[seriesId].stackedData.reduce((seriesAcc, stackedValue) => {
      const [base, value] = getValues(stackedValue);
      return [Math.min(base, value, seriesAcc[0]), Math.max(base, value, seriesAcc[1])];
    }, getValues(series[seriesId].stackedData[0]));
    if (acc[0] === null || acc[1] === null) {
      return [seriesMin, seriesMax];
    }
    return [Math.min(seriesMin, acc[0]), Math.max(seriesMax, acc[1])];
  }, [null, null]);
};