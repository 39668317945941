import _extends from "@babel/runtime/helpers/esm/extends";
function defaultizeValueFormatter(series, defaultValueFormatter) {
  const defaultizedSeries = {};
  Object.keys(series).forEach(seriesId => {
    defaultizedSeries[seriesId] = _extends({
      valueFormatter: defaultValueFormatter
    }, series[seriesId]);
  });
  return defaultizedSeries;
}
export default defaultizeValueFormatter;