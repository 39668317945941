/* eslint-disable class-methods-use-this */
import defaultMoment from 'moment';
import BaseAdapterMoment from '@date-io/moment';
// From https://momentjs.com/docs/#/displaying/format/
const formatTokenMap = {
  // Year
  Y: 'year',
  YY: 'year',
  YYYY: 'year',
  // Month
  M: 'month',
  MM: 'month',
  MMM: {
    sectionType: 'month',
    contentType: 'letter'
  },
  MMMM: {
    sectionType: 'month',
    contentType: 'letter'
  },
  // Day of the month
  D: 'day',
  DD: 'day',
  // Day of the week
  E: 'weekDay',
  e: 'weekDay',
  d: 'weekDay',
  dd: {
    sectionType: 'weekDay',
    contentType: 'letter'
  },
  ddd: {
    sectionType: 'weekDay',
    contentType: 'letter'
  },
  dddd: {
    sectionType: 'weekDay',
    contentType: 'letter'
  },
  // Meridiem
  A: 'meridiem',
  a: 'meridiem',
  // Hours
  H: 'hours',
  HH: 'hours',
  h: 'hours',
  hh: 'hours',
  k: 'hours',
  kk: 'hours',
  // Minutes
  m: 'minutes',
  mm: 'minutes',
  // Seconds
  s: 'seconds',
  ss: 'seconds'
};
export class AdapterMoment extends BaseAdapterMoment {
  constructor(...args) {
    super(...args);
    this.isMUIAdapter = true;
    this.formatTokenMap = formatTokenMap;
    this.escapedCharacters = {
      start: '[',
      end: ']'
    };
    this.expandFormat = format => {
      // @see https://github.com/moment/moment/blob/develop/src/lib/format/format.js#L6
      const localFormattingTokens = /(\[[^[]*\])|(\\)?(LTS|LT|LL?L?L?|l{1,4})|./g;
      return format.match(localFormattingTokens).map(token => {
        const firstCharacter = token[0];
        if (firstCharacter === 'L' || firstCharacter === ';') {
          return defaultMoment.localeData(this.getCurrentLocaleCode()).longDateFormat(token);
        }
        return token;
      }).join('');
    };
    this.getCurrentLocaleCode = () => {
      return this.locale || defaultMoment.locale();
    };
    this.getFormatHelperText = format => {
      return this.expandFormat(format).replace(/a/gi, '(a|p)m').toLocaleLowerCase();
    };
    this.getWeekNumber = date => {
      return date.week();
    };
    this.getWeekdays = () => {
      return defaultMoment.weekdaysShort(true);
    };
    this.is12HourCycleInCurrentLocale = () => {
      return /A|a/.test(defaultMoment.localeData(this.getCurrentLocaleCode()).longDateFormat('LT'));
    };
  }
}