export const getExtremumX = params => {
  var _axis$data, _axis$data2;
  const {
    axis
  } = params;
  const minX = Math.min(...((_axis$data = axis.data) != null ? _axis$data : []));
  const maxX = Math.max(...((_axis$data2 = axis.data) != null ? _axis$data2 : []));
  return [minX, maxX];
};
export const getExtremumY = params => {
  const {
    series,
    axis,
    isDefaultAxis
  } = params;
  return Object.keys(series).filter(seriesId => series[seriesId].yAxisKey === axis.id || isDefaultAxis && series[seriesId].yAxisKey === undefined).reduce((acc, seriesId) => {
    const [seriesMin, seriesMax] = series[seriesId].stackedData.reduce((seriesAcc, values) => [Math.min(...values, ...(seriesAcc[0] === null ? [] : [seriesAcc[0]])), Math.max(...values, ...(seriesAcc[1] === null ? [] : [seriesAcc[1]]))], series[seriesId].stackedData[0]);
    return [acc[0] === null ? seriesMin : Math.min(seriesMin, acc[0]), acc[1] === null ? seriesMax : Math.max(seriesMax, acc[1])];
  }, [null, null]);
};